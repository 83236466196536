// import React from "react";
// import Slider from "react-slick";
// import "./Slide.css";

// const Slide = ({ children, slidesToShow, arrowsScroll }) => {
//   const settings = {
//     slidesToShow: slidesToShow,
//     slidesToScroll: arrowsScroll,
//     infinite: false, // Change to false if you don't want an infinite loop
//     arrows: false // Show arrows
//   };

//   return (
//     <div className="slide">
//       <div className="container">
//         <Slider {...settings}>
//           {children}
//         </Slider>
//       </div>
//     </div>
//   );
// };

// export default Slide;



// NOTE: This is kinda wodking below one

// import React, { useState } from "react";
// import "./Slide.css";

// const Slide = ({ children, slidesToShow, arrowsScroll }) => {
//   const [currentIndex, setCurrentIndex] = useState(0);

//   const handlePrev = () => {
//     setCurrentIndex((prevIndex) =>
//       prevIndex === 0 ? children.length - slidesToShow : prevIndex - arrowsScroll
//     );
//   };

//   const handleNext = () => {
//     setCurrentIndex((prevIndex) =>
//       prevIndex === children.length - slidesToShow ? 0 : prevIndex + arrowsScroll
//     );
//   };

//   return (
//     <div className="slide">
//       <div className="container">
//         <div className="slider">
//           <div
//             className="slides"
//             style={{
//               transform: `translateX(-${currentIndex * (100 / slidesToShow)}%)`,
//             }}
//           >
//             {children}
//           </div>
//         </div>
//         <button className="prev" onClick={handlePrev}>
//           Prev
//         </button>
//         <button className="next" onClick={handleNext}>
//           Next
//         </button>
//       </div>
//     </div>
//   );
// };

// export default Slide;



import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./Slide.css";

const Slide = ({ children, slidesToShow, arrowsScroll,autoPlaySpeed }) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: slidesToShow.superLargeDesktop || 7
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: slidesToShow.desktop || 5
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: slidesToShow.tablet || 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: slidesToShow.mobile || 1
    }
  };

  return (
    <div className="slide">
      <div className="container">
        <Carousel 
          responsive={responsive}
          arrows={false} // Show arrows
          slidesToScroll={arrowsScroll}
          infinite={true} // Change to true if you want an infinite loop
          itemClass="carousel-item-padding-0-px"
          autoPlay
          autoPlaySpeed={autoPlaySpeed || 3000}
          
          

        >
          {children}
        </Carousel>
      </div>
    </div>
  );
};

export default Slide;

